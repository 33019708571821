import React, { Component } from "react";
import { connect } from "react-redux";
import { Spring, animated } from "react-spring";
import BackButtonHeader from "../../../Elements/BackButtonHeader";
import { getUserWallet } from "../../../../services/User/action";
import moment from "moment";
import Loading from "../../../helpers/loading-blue";

export class PrivacyPolicy extends Component {
  state = {
    loading: true,
  };

  checkDate = (i) => {
    const transactions = this.props.wallet.walletTransactions;
    if (transactions[i] && transactions[i - 1]) {
      let date = new Date(transactions[i].created_at).toDateString();

      let prevdate = new Date(transactions[i - 1].created_at).toDateString();
      if (date === prevdate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const { wallet } = this.props;

    return (
      // <Spring
      //     from={{ opacity: 0, marginLeft: '500px' }}
      //     to={{ opacity: 1, marginLeft: '0px' }}
      // // config={{ duration: 1000 }}
      // >
      //     {styles => (
      //         <animated.div style={styles}>
      <React.Fragment>
        <BackButtonHeader title={"Privacy Policy"} />
        <React.Fragment>
          <div
            className="mx-3"
            style={{
              marginTop: "7rem",
              marginBottom: "7rem",
              padding: "0px 10px",
            }}
          >
            <h1>Privacy Policy</h1>
            <h4 style={{ marginTop: "1rem" }}>
            Personal Information We Collect: <br />
            </h4>
            We collects several types of information regarding our Guest User and Register Users: We collects personally identifiable information from our users. The personal information collected by us mainly consists of Contact Details (i.e. Email Address, Contact Number), Personal Details (i.e. User Name), Billing Details (i.e. Physical Billing address, Payment Method, Transaction Details), User Preferences (i.e. Preferences of order method, Time-zone, Language), User Comments (i.e. Feedback, Complain). We collect information so that you can place order, request information and support and make product suggestions. Our website and mobile application use forms to collects information. We receive and store your information you enter on our website or mobile applications, give us any other way like email, telephone or other communications with our customer service team. If you contact us for support, we will keep an internal records for that also.
            <h4 style={{ marginTop: "1rem" }}>We Use the Information We Collects:</h4>
            Information will be used to process your order and to manage your account. We will use your information to contact you in response of your suggestion or query, regarding the functionality changes to our products, any special offers which will be suitable to you and changes in Privacy Policy. We used to communicate with you through emails, messages or call. We use emails to confirm orders placed by you or to send information requested by you. We are also providing email links to contact us directly. We are eager to reply you for your message. The information which you have send to us may be reviewed, discarded or used. These information may be used to improve our Website, Application, Product and Services. To process your order we may send your information to credit reference and fraud prevention agencies.
            <h4 style={{ marginTop: "1rem" }}>Cookies:</h4>
            A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. Cookies can also help customize the site for visitors. Personal information cannot be collected via cookies and other tracking technology, however, if you previously provided personally identifiable information, cookies may be tied to such information. We are not storing any cookies to browser by code, its browsers feature to store cookies by clicking on save password.

            <h4 style={{ marginTop: "1rem" }}>Security:</h4>
            We believe the security of your information is a serious issue and we are committed to protecting the information we receive from you. We take precautions to protect your information. We use security measures to protect against the loss, misuse or alteration of the information under our control. When you submit sensitive information via website or application, your information is protected both online and offline. Whenever you enter sensitive information on our forms, the information is encrypted using MD5 and transmitted to us in a secure way. While we use encryption to protect your sensitive information transmitted online, we also protect your information offline. The computers/servers in which we store personally identifiable information are kept in a secure environment. We will retain personal information only as long as our business needs require. We will then destroy or render unreadable any such information upon disposal. However, we do not guarantee that unauthorized access will never occur. Users who have registered to the site agree to keep their password in strict confidence and not disclose such password to any third party.
            <h4 style={{ marginTop: "1rem" }}>Sharing Information with Third Party:</h4>
            We may share information about you to third parties to provide various services on our behalf to fulfil your request (i.e. to ship your order, to process credit card payments, serving advertisements, conducting surveys, Customer relationship managements, performing analysis of our service). We will only share information about you that is necessary for third party to provide the requested service. These third parties are prohibited from retaining, sharing, storing or using your personally identifiable information for any other purpose. This policy does not apply to information that you provide to any third party such as stores at which you make reservation and/or pay through our services and social network that you use in connection with our services.

            <h4 style={{ marginTop: "1rem" }}>Using Instagram</h4>
            “SuperJoe Joe Merchant app is available to all Merchant. Merchant can use this app to manage store details, manage orders, setting up all menus, handling payment, delivery method and so many other features. We provide feature of store Photos where Merchant can upload photo for their menu, icecream and ambience. We are using Instagram API to allow Merchant to login into his Instagram account and upload his Instagram photos for menu,icecream and ambience photos.
            <h4 style={{ marginTop: "1rem" }}>Information collection and Use</h4>
            We collect and use your information to provide feature of uploading photos using Instagram account. We do not disclose your private information to any third party apps.
            <h4 style={{ marginTop: "1rem" }}>Changes in Privacy Policy:</h4>
            We reserve all rights to modify this Privacy Policy at any time according to our need. Our Privacy Policy may changes from time to time and all updates will be posted on this Website. Our Privacy Policy may be change according to changes in law, our features and services, advances in technology. Please visit our Privacy Policy page with periods of time for update in Privacy Policy. Please review our Privacy Policy carefully. Our continued use of the Services following the posting of changes to Privacy Policy will constitute your consent and acceptance of those changes.
            <h4 style={{ marginTop: "1rem" }}>Contact Details</h4>
            If you have any Query, Feedback or Concern about this Privacy Policy, you can write to us or reach out to us using following contact details.
           
          </div>
        </React.Fragment>
      </React.Fragment>

      //         </animated.div>
      //     )
      //     }
      // </Spring>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  wallet: state.user.wallet,
});
