import React, { Component } from "react";
import { connect } from "react-redux";
import { Spring, animated } from "react-spring";
import BackButtonHeader from "../../../Elements/BackButtonHeader";
import { getUserWallet } from "../../../../services/User/action";
import moment from "moment";
import Loading from "../../../helpers/loading-blue";

export class TermsCondition extends Component {
  state = {
    loading: true,
  };

  checkDate = (i) => {
    const transactions = this.props.wallet.walletTransactions;
    if (transactions[i] && transactions[i - 1]) {
      let date = new Date(transactions[i].created_at).toDateString();

      let prevdate = new Date(transactions[i - 1].created_at).toDateString();
      if (date === prevdate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const { wallet } = this.props;

    return (
      // <Spring
      //     from={{ opacity: 0, marginLeft: '500px' }}
      //     to={{ opacity: 1, marginLeft: '0px' }}
      // // config={{ duration: 1000 }}
      // >
      //     {styles => (
      //         <animated.div style={styles}>
      <React.Fragment>
        <BackButtonHeader title={"Terms & Conditions"} />
        <React.Fragment>
          <div
            className="mx-3"
            style={{
              marginTop: "7rem",
              marginBottom: "7rem",
              padding: "0px 10px",
            }}
          >
            <h1>Terms & Conditions</h1>
            <h6 style={{ marginTop: "1rem" }}>
              Welcome to SuperJoe! <br />
            </h6>
            These terms and conditions outline the rules and regulations for the
            use of SuperJoe's Website, located at
            https://superjoe.howincloud.com/landing/. By accessing this website
            we assume you accept these terms and conditions. Do not continue to
            use SuperJoe if you do not agree to take all of the terms and
            conditions stated on this page. The following terminology applies to
            these Terms and Conditions, Privacy Statement and Disclaimer Notice
            and all Agreements: "Client", "You" and "Your" refers to you, the
            person log on this website and compliant to the Companyâs terms and
            conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers
            to our Company. "Party", "Parties", or "Us", refers to both the
            Client and ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Clientâs needs in respect of
            provision of the Companyâs stated services, in accordance with and
            subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same. SuperJoe enables transactions on its Platform
            between participating stores/merchants and buyers, dealing in
            prepared food and beverages, The buyers can choose and place orders
            from a variety of products listed and offered for sale by various
            merchants. SuperJoe enables delivery Orders or completion of Tasks
            at select localities of India by connecting pick-up and delivery
            guys who will be responsible for providing the pick-up and delivery
            services and completing Tasks initiated by the customers.
            <h4 style={{ marginTop: "1rem" }}>Use of Platform and Services</h4>
            All commercial/contractual terms are offered by and agreed to
            between Buyers and Merchants alone with respect to products being
            offered by the Merchants. The commercial/contractual terms include
            without limitation price, applicable taxes, delivery charges,
            payment terms, date, period and mode of delivery. The price of the
            product offered by the Merchants are determined by the Merchant
            itself and SuperJoe has no role to play in such determination of
            price in any way whatsoever. Upon acceptance of any Order or Task by
            the Delivery Guys, the pickup and delivery services undertaken by
            him/her.
            <h4 style={{ marginTop: "1rem" }}>Access Platform</h4>
            You may access the Platform by downloading the SuperJoe App in your
            Android or IOS device and registering to create an SuperJoe account
            using your mobile number We will create your SuperJoe Account for
            your use of the Platform services based upon the personal
            information you provide to us. You agree to provide accurate,
            current and complete information during the registration process and
            update such information to keep it accurate, current and complete.
            We reserve the right to suspend or terminate your SuperJoe Account
            and your access to the Services (i) if any information provided
            during the registration process or thereafter proves to be
            inaccurate, not current or incomplete; (ii) if it is believed that
            your actions may cause legal liability for you, other users or us;
            and/or (iii) if you are found to be non-compliant with the Terms of
            Use or other SuperJoe policies. Upon registering with SuperJoe, even
            if the customer is under "DND" , SuperJoe will have the permission
            to call the customer for order confirmation
            <h4 style={{ marginTop: "1rem" }}>Ordering and payment</h4>
            The Platform allows the Buyers to place Orders and upon acceptance
            of such Orders by the Merchants, SuperJoe will, subject to the terms
            and conditions set out herein, facilitates delivery of goods or
            services, or completion of the orders SuperJoe does not own, sell,
            resell on its own such products offered by the Merchants, and/or
            does not control the Merchants or the related services provided in
            connection thereof. Buyer understands that any Order that he/she
            places shall be subject to the terms and conditions set out in these
            Terms of Use including, but not limited to, product availability,
            delivery location serviceability, and acceptance of Orders by
            Merchants/delivery guys. As a general rule, all Orders placed on the
            Platform and Delivery Services are treated as confirmed. Upon
            Buyerâs successful completion of booking an Order, an autocall goes
            to the Buyer on the mobile number provided to confirm the details of
            such Order. After confirmation, it will be notified to the store and
            the task will be assigned by us to a a Delivery Guy. Whne the food
            is ready, it will be picked up and delivered by by the delivery Guy
            to the customer which makes the task completed. In addition to the
            foregoing, we may also contact you by phone and / or email to inform
            and confirm any change in the Order, due to availability or
            unavailability or change in Order or change in price of any item in
            the Order as informed by the Merchant. Please note that any change
            or confirmation of the Order shall be treated as final. It is
            clarified that SuperJoe reserves the right to not to process Buyer's
            Order in the event Buyer or Merchant or Delivery Guy is unavailable
            on the phone or any other means of communication at the time when we
            call you for confirming the Order and such event the provisions of
            the Cancellation and Refund Policy shall be applicable. All payments
            made against the Orders or Services on the Platform by you shall be
            compulsorily in Indian Rupees acceptable in the Republic of India.
            The Platform will not facilitate transactions with respect to any
            other form of currency with respect to the Orders or Services made
            on Platform. You can pay by (i) credit card or debit card or net
            banking; (ii) any other RBI approved payment method at the time of
            booking an Order; or (iii) credit or debit card or cash at the time
            of delivery. You understand, accept and agree that the payment
            facility provided by SuperJoe is neither a banking nor financial
            service but is merely a facilitator providing an electronic,
            automated online electronic payment, receiving payment on delivery,
            collection and remittance facility for the transactions on the
            Platform using the existing authorized banking infrastructure and
            credit card payment gateway networks. Further, by providing payment
            facility, SuperJoe is neither acting as trustees nor acting in a
            fiduciary capacity with respect to the transaction or the
            transaction price. Buyer agrees to pay for the total amount for the
            Order placed on the Platform. SuperJoe will collect the total amount
            in accordance with these Terms of Use and the pricing terms set
            forth in the applicable listing of product or store service for the
            particular Merchant, apart from the delivery fees for Delivery
            Services. Please note that we cannot control any amount that may be
            charged to Buyer by his/her bank related to our collection of the
            total amount, and we disclaim all liability in this regard. Customer
            Enquiries / Reports on stores / Item Missing / Item Change / any
            other complaints will be resolved by 24 - 32 Hours.
            <h4 style={{ marginTop: "1rem" }}>Terms of Services</h4>
            The Buyer agrees and acknowledges that SuperJoe shall not be
            responsible for: The food orders provided by the Merchants suiting
            your requirements and taste; The Merchant's services not being up to
            Buyer expectations or leading to any loss, harm or damage to
            him/her; The availability or unavailability of certain items on the
            menu; The Merchant serving the incorrect Orders; or Product
            liability of products provided by Merchants. Buyers may not be able
            to avail Services if their delivery location is outside SuperJoeâs
            current scope of Service. SuperJoe will keep the Buyer informed of
            the same at the time of confirming his/her Order booking. Buyerâs
            Order will be only delivered to the address designated by him/her at
            the time of placing the Order on the Platform. Buyerâs Order will be
            cancelled in the event of any change of the address as informed by
            the Delivery Guy and Buyer shall not be entitled to any refund for
            the same. Delivery of goods and services in the event of change of
            the delivery location shall be subject to acceptance by the Delivery
            Guy or sole discretion of SuperJoe.
            <h4 style={{ marginTop: "1rem" }}>License</h4>
            Unless otherwise stated, SuperJoe and/or its licensors own the
            intellectual property rights for all material on SuperJoe. All
            intellectual property rights are reserved. You may access this from
            SuperJoe for your own personal use subjected to restrictions set in
            these terms and conditions. You must not: Republish material from
            SuperJoe Sell, rent or sub-license material from SuperJoe Reproduce,
            duplicate or copy material from SuperJoe Redistribute content from
            SuperJoe You hereby grant SuperJoe a non-exclusive license to use,
            reproduce, edit and authorize others to use, reproduce and edit any
            of your Comments in any and all forms, formats or media.
            <h4 style={{ marginTop: "1rem" }}>Hyperlinking to our Content</h4>
            We will approve link requests from other organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of SuperJoe; and
            (d) the link is in the context of general resource information.
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            partyâs site. If you are one of the organizations listed in
            paragraph 2 above and are interested in linking to our website, you
            must inform us by sending an e-mail to SuperJoe. Please include your
            name, your organization name, contact information as well as the URL
            of your site, a list of any URLs from which you intend to link to
            our Website, and a list of the URLs on our site to which you would
            like to link. Wait 2-3 weeks for a response. Approved organizations
            may hyperlink to our Website as follows: By use of our corporate
            name; or By use of the uniform resource locator being linked to; or
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            partyâs site. No use of SuperJoe's logo or other artwork will be
            allowed for linking absent a trademark license agreement.
            <h4 style={{ marginTop: "1rem" }}>iFrames</h4>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
            <h4 style={{ marginTop: "1rem" }}>Content Liability</h4>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
            <h4 style={{ marginTop: "1rem" }}>Amendments</h4>
            These Terms of Use are subject to modifications. We reserve the
            right to modify or change these Terms of Use and other SuperJoe
            policies at any time by posting modified documents on the Platform
            and notifying you of the same for your perusal. You shall be liable
            to update yourself of such changes, if any, by accessing the same.
            You shall, at all times, be responsible for regularly reviewing the
            Terms of Use and the other SuperJoe policies and note the changes
            made on the Platform. Your continued usage of the Services after any
            change is posted constitutes your acceptance of the amended Terms of
            Use and other SuperJoe policies. As long as you comply with these
            Terms of Use, SuperJoe grants you a personal, non-exclusive,
            non-transferable, limited privilege to access, enter, and use the
            Platform. By accepting these Terms of Use, you also accept and agree
            to be bound by the other terms and conditions and SuperJoe policies
            (including but not limited to Cancellation & Refund Policy, Privacy
            Policy and Notice and Take Down Policy) as may be posted on the
            Platform from time to time.
            <h4 style={{ marginTop: "1rem" }}>Reservation of Rights</h4>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and itâs linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
            <h4 style={{ marginTop: "1rem" }}>
              Removal of links from our website
            </h4>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly. We do not ensure that the information
            on this website is correct, we do not warrant its completeness or
            accuracy; nor do we promise to ensure that the website remains
            available or that the material on the website is kept up to date.
            <h4 style={{ marginTop: "1rem" }}>Disclaimer</h4>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will: limit
            or exclude our or your liability for death or personal injury; limit
            or exclude our or your liability for fraud or fraudulent
            misrepresentation; limit any of our or your liabilities in any way
            that is not permitted under applicable law; or exclude any of our or
            your liabilities that may not be excluded under applicable law. The
            platform may be under constant upgrades, and some functions and
            features may not be fully operational. Due to the vagaries that can
            occur in the electronic distribution of information and due to the
            limitations inherent in providing information obtained from multiple
            sources, there may be delays, omissions, or inaccuracies in the
            content provided on the platform or delay or errors in functionality
            of the platform. As a result, we do not represent that the
            information posted is correct in every case. We expressly disclaim
            all liabilities that may arise as a consequence of any unauthorized
            use of credit/ debit cards. SuperJoe disclaims and all liability
            that may arise due to any violation of any applicable laws including
            the law applicable to products and services offered by the merchant
            or Delivery Guy. While the materials provided on the platform were
            prepared to provide accurate information regarding the subject
            discussed, the information contained in these materials is being
            made available with the understanding that we make no guarantees,
            representations or warranties whatsoever, whether expressed or
            implied, with respect to professional qualifications, expertise,
            quality of work or other information herein. Further, we do not, in
            any way, endorse any service offered or described herein. In no
            event shall we be liable to you or any third party for any decision
            made or action taken in reliance on such information. We shall not
            be liable to you or anyone else for any losses or injury arising out
            of or relating to the information provided on the platform. In no
            event will we or our employees, affiliates, authors or agents be
            liable to you or any third party for any decision made or action
            taken by your reliance on the content contained herein. The
            limitations and prohibitions of liability set in this Section and
            elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty. As long as the website and the
            information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </div>
        </React.Fragment>
      </React.Fragment>

      //         </animated.div>
      //     )
      //     }
      // </Spring>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  wallet: state.user.wallet,
});
